<template>
  <b-card-actions action-collapse title="Daftar Menu">
    <!-- ref="refreshCard" -->
    <!-- action-refresh
    @refresh="refreshStop('refreshCard')" -->
    <b-row >
      <b-col md="2" sm="4" class="my-1">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          v-b-modal.modal-tambah
          variant="primary"
          v-if="allowCreate()"
        >
          <feather-icon icon="PlusIcon" class="mr-50" />
          {{ $t('Add') }}
        </b-button>
        <b-modal
          v-if="allowCreate()"
          no-close-on-backdrop no-close-on-esc
          v-model="showModalAdd"
          id="modal-tambah"
          cancel-variant="secondary"
          ok-title="Add"
          cancel-title="Batal"
          centered
          title="Form Menu"
        >
          <b-form>
            <b-row>
              <b-col cols="12">
                <b-form-group>
                  <label for="urutan">Urutan</label>
                  <b-form-input
                  type="number"
                    v-model="form.urutan"
                    id="urutan"
                    placeholder="Urutan"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group>
                  <label for="name">Nama Menu</label>
                  <b-form-input
                    v-model="form.name"
                    id="name"
                    type="text"
                    placeholder="Nama Menu"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="8">
                <b-form-group>
                  <label for="icon">Icon (Feather)</label>
                  <b-form-select v-model="form.icon" :options="icons" label="Icon" />
                </b-form-group>
              </b-col>
              <b-col cols="4">
                <b-form-group  v-if="form.icon">
                  <label for="icon"></label>
                  <b-card-text class="mt-1 mb-0">
                    <feather-icon :icon="form.icon" size="80" />
                  </b-card-text>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group>
                  <!-- <b-form-input v-model="form.url" id="url" type="url" placeholder="URL" /> -->
                  <label for="">Halaman Tujuan</label>
                  <v-select
                    v-model="form.url"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="listRoutes"
                    :reduce="option => option.value"
                    label="text"
                   />
                </b-form-group>
              </b-col>
              <b-col cols="12" class="mb-2">
                <b-form-group>
                  <label for="">Query URl (opsional)</label>
                  <b-form-input placeholder="Ex: ?param=value" v-model="query"></b-form-input>
                </b-form-group>
              </b-col>
              <b-form-group>
              <b-form-checkbox
                v-model="form.create" switch inline
              >
                Add
              </b-form-checkbox>
            </b-form-group>
            <b-form-group>
              <b-form-checkbox
                v-model="form.update" switch inline
              >
                Ubah
              </b-form-checkbox>
            </b-form-group>
            <b-form-group>
              <b-form-checkbox
                v-model="form.delete" switch inline
              >
                Hapus
              </b-form-checkbox>
            </b-form-group>
            </b-row>
          </b-form>

          <template #modal-footer>
            <section class="d-flex justify-content-end align-items-center">
              <b-button size="sm" class="mr-1" variant="success" @click="submit">
                Add
              </b-button>
              <b-button size="sm" class="mr-1" variant="primary" @click="resetForm">
                Hapus isian
              </b-button>
              <b-button size="sm" variant="danger" @click="showModalAdd = false">
                Keluar
              </b-button>
            </section>
          </template>
        </b-modal>
        <b-modal
          v-if="allowUpdate()"
          no-close-on-backdrop no-close-on-esc
          v-model="showModalEdit"
          id="modal-ubah"
          cancel-variant="secondary"
          ok-:title="$t('Edit')"
          cancel-title="Batal"
          centered
          title="Form Ubah"
        >
          <b-form>
            <b-row>
              <b-col cols="12">
                <b-form-group>
                  <label for="urutan">Urutan</label>
                  <b-form-input
                    v-model="form.urutan"
                    id="urutan"
                    type="number"
                    placeholder="Urutan"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group>
                  <label for="name">Nama Menu</label>
                  <b-form-input
                    v-model="form.name"
                    id="name"
                    type="text"
                    placeholder="Nama Menu"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="8">
                <b-form-group>
                  <label for="icon">Icon (Feather)</label>
                  <!-- <b-form-input v-model="icon" id="icon" type="icon" placeholder="Icon" /> -->
                  <b-form-select v-model="form.icon" :options="icons" label="Icon" />
                </b-form-group>
              </b-col>
              <b-col cols="4">
                <b-form-group>
                  <label for="icon"></label>
                  <b-card-text class="mt-1 mb-0">
                    <feather-icon :icon="form.icon" size="80" />
                  </b-card-text>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group>
                  <label for="url">Halaman Tujuan</label>
                  <v-select
                    v-model="form.url"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="listRoutes"
                    :reduce="option => option.value"
                    label="text"
                   />
                </b-form-group>
              </b-col>
              <b-form-group>
              <b-form-checkbox
                v-model="form.create" switch inline
              >
                Add
              </b-form-checkbox>
            </b-form-group>
            <b-form-group>
              <b-form-checkbox
                v-model="form.update" switch inline
              >
                Ubah
              </b-form-checkbox>
            </b-form-group>
            <b-form-group>
              <b-form-checkbox
                v-model="form.delete" switch inline
              >
                Hapus
              </b-form-checkbox>
            </b-form-group>
            </b-row>
          </b-form>

          <template #modal-footer>
            <section class="d-flex justify-content-end align-items-center">
              <b-button size="sm" class="mr-1" variant="info" @click="submit">
                Ubah
              </b-button>
              <b-button size="sm" class="mr-1" variant="primary" @click="resetForm">
                Hapus isian
              </b-button>
              <b-button size="sm" variant="danger" @click="showModalEdit = false">
                Keluar
              </b-button>
            </section>
          </template>
        </b-modal>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="2" sm="4" class="my-1">
        <b-form-group class="mb-0">
          <label class="d-inline-block text-sm-left mr-50">Per page</label>
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            :options="pageOptions"
            class="w-50"
          />
        </b-form-group>
      </b-col>
      <b-col md="4" sm="8" class="my-1">
        <b-form-group
          :label="$t('Sort')"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          label-for="sortBySelect"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-select
              id="sortBySelect"
              v-model="sortBy"
              :options="sortOptions"
              class="w-75"
            >
              <template v-slot:first>
                <option value="">-- none --</option>
              </template>
            </b-form-select>
            <b-form-select v-model="sortDesc" size="sm" :disabled="!sortBy" class="w-25">
              <option :value="false">Asc</option>
              <option :value="true">Desc</option>
            </b-form-select>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col md="6" class="my-1">
        <b-form-group
          :label="$t('Filter')"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="text"
              
            />
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">{{$t('Clear')}}</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-table
          striped
          small
          hover
          responsive
          :per-page="perPage"
          :current-page="currentPage"
          :items="items"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
        >
          <template #cell(no)="row">
            <strong class="text-center">
              {{ row.index + 1 }}
            </strong>
          </template>
          <!-- <template #cell(avatar)="data">
            <b-avatar :src="data.value" />
          </template>-->
          

          <template #cell(icon)="data">
            <feather-icon :icon="data.value" class="align-middle" />
            {{ data.value }}
          </template>
          <template #cell(create)="{item}">
            <feather-icon v-if="item.create" icon="CheckIcon" class="text-success" size="24" />
                <feather-icon v-else icon="XIcon" class="text-danger" size="24" />
          </template> 
          <template #cell(update)="{item}">
            <feather-icon v-if="item.update" icon="CheckIcon" class="text-success" size="24" />
                <feather-icon v-else icon="XIcon" class="text-danger" size="24" />
          </template> 
          <template #cell(delete)="{item}">
            <feather-icon v-if="item.delete" icon="CheckIcon" class="text-success" size="24" />
            <feather-icon v-else icon="XIcon" class="text-danger" size="24" />
          </template> 
          <template #cell(actions)="row">
            <b-dropdown
              id="dropdown-3"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              right
              class="dropdown-icon-wrapper"
            >
              <template #button-content>
                <!-- <span class="mr-1"></span> -->
                <feather-icon icon="ListIcon" size="16" class="align-middle" />
              </template>
              <!-- <b-dropdown-item> -->
              <!-- <feather-icon icon="ListIcon" /> -->
              <!-- <b-dropdown-item
                ><b-button
                  title="'Detail'"
                  size="sm"
                  @click="info(row.item, row.index, $event.target)"
                  class="mr-1"
                  variant="outline-secondary"
                >
                  <feather-icon icon="ListIcon" />Detail
                </b-button></b-dropdown-item
              > -->
              <b-dropdown-item
                ><b-button
                v-if="allowUpdate()"
                  title="'Ubah'"
                  size="sm"
                  @click="edit(row.item)"
                  class="mr-1"
                  variant="outline-info"
                >
                  <feather-icon icon="EditIcon" />Ubah
                </b-button></b-dropdown-item
              >
              <b-dropdown-item
                ><b-button
                v-if="allowDelete()"
                  title="'Hapus'"
                  size="sm"
                  @click="remove(row.item)"
                  class="mr-1"
                  variant="outline-danger"
                >
                  <feather-icon icon="TrashIcon" />Hapus
                </b-button></b-dropdown-item
              >
            </b-dropdown>

            <!-- <b-button size="sm" @click="row.toggleDetails" class="mr-1">
              {{ row.detailsShowing ? "Hide" : "Show" }} Details 2
            </b-button> -->
          </template>

          <template #row-details="row">
            <b-card>
              <ul>
                <li v-for="(value, key) in row.item" :key="key">
                  {{ key }}: {{ value }}
                </li>
              </ul>
            </b-card>
          </template>
        </b-table>
      </b-col>
      <b-modal :id="infoModal.id" :title="infoModal.title" ok-only @hide="resetInfoModal">
        <pre>{{ infoModal.content }}</pre>
      </b-modal>

      <b-col cols="12">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="center"
          size="sm"
          class="my-0"
        />
      </b-col>
    </b-row>
  </b-card-actions>
</template>

<script>
import Ripple from "vue-ripple-directive";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from 'vue-select'
import {
  BTable,BFormCheckbox ,
  VBTooltip,
  BDropdown,
  BDropdownItem,
  BAvatar,
  BForm,
  BCardText,
  BBadge,
  BModal,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
} from "bootstrap-vue";

export default {
  components: {
    vSelect,
    BForm,
  BCardText,
    BCardActions,BFormCheckbox ,
    ToastificationContent,
    VBTooltip,
    BModal,
    BDropdown,
    BDropdownItem,
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      query: null,
      showModalEdit: false,
      showModalAdd: false,
        id: null,
      form: {
      create: false,
      update: false,
      delete: false,
      urutan: null,
      name: null,
      url: null,
      icon: "",
      },
      id: null,
      create: null,
      update: null,
      delete: null,
      urutan: null,
      name: null,
      url: null,
      icon: null,
      icons: [
        "ActivityIcon",
        "AirplayIcon",
        "AlertCircleIcon",
        "AlertOctagonIcon",
        "AlertTriangleIcon",
        "AlignCenterIcon",
        "AlignJustifyIcon",
        "AlignLeftIcon",
        "AlignRightIcon",
        "AnchorIcon",
        "ApertureIcon",
        "ArchiveIcon",
        "ArrowDownCircleIcon",
        "ArrowDownLeftIcon",
        "ArrowDownRightIcon",
        "ArrowDownIcon",
        "ArrowLeftCircleIcon",
        "ArrowLeftIcon",
        "ArrowRightCircleIcon",
        "ArrowRightIcon",
        "ArrowUpCircleIcon",
        "ArrowUpLeftIcon",
        "ArrowUpRightIcon",
        "ArrowUpIcon",
        "AtSignIcon",
        "AwardIcon",
        "BarChart2Icon",
        "BarChartIcon",
        "BatteryChargingIcon",
        "BatteryIcon",
        "BellOffIcon",
        "BellIcon",
        "BluetoothIcon",
        "BoldIcon",
        "BookOpenIcon",
        "BookIcon",
        "BookmarkIcon",
        "BoxIcon",
        "BriefcaseIcon",
        "CalendarIcon",
        "CameraOffIcon",
        "CameraIcon",
        "CastIcon",
        "CheckCircleIcon",
        "CheckSquareIcon",
        "CheckIcon",
        "ChevronDownIcon",
        "ChevronLeftIcon",
        "ChevronRightIcon",
        "ChevronUpIcon",
        "ChevronsDownIcon",
        "ChevronsLeftIcon",
        "ChevronsRightIcon",
        "ChevronsUpIcon",
        "ChromeIcon",
        "CircleIcon",
        "ClipboardIcon",
        "ClockIcon",
        "CloudDrizzleIcon",
        "CloudLightningIcon",
        "CloudOffIcon",
        "CloudRainIcon",
        "CloudSnowIcon",
        "CloudIcon",
        "CodeIcon",
        "CodepenIcon",
        "CodesandboxIcon",
        "CoffeeIcon",
        "ColumnsIcon",
        "CommandIcon",
        "CompassIcon",
        "CopyIcon",
        "CornerDownLeftIcon",
        "CornerDownRightIcon",
        "CornerLeftDownIcon",
        "CornerLeftUpIcon",
        "CornerRightDownIcon",
        "CornerRightUpIcon",
        "CornerUpLeftIcon",
        "CornerUpRightIcon",
        "CpuIcon",
        "CreditCardIcon",
        "CropIcon",
        "CrosshairIcon",
        "DatabaseIcon",
        "DeleteIcon",
        "DiscIcon",
        "DivideCircleIcon",
        "DivideSquareIcon",
        "DivideIcon",
        "DollarSignIcon",
        "DownloadCloudIcon",
        "DownloadIcon",
        "DribbbleIcon",
        "DropletIcon",
        "Edit2Icon",
        "Edit3Icon",
        "EditIcon",
        "ExternalLinkIcon",
        "EyeOffIcon",
        "EyeIcon",
        "FacebookIcon",
        "FastForwardIcon",
        "FeatherIcon",
        "FigmaIcon",
        "FileMinusIcon",
        "FilePlusIcon",
        "FileTextIcon",
        "FileIcon",
        "FilmIcon",
        "FilterIcon",
        "FlagIcon",
        "FolderMinusIcon",
        "FolderPlusIcon",
        "FolderIcon",
        "FramerIcon",
        "FrownIcon",
        "GiftIcon",
        "GitBranchIcon",
        "GitCommitIcon",
        "GitMergeIcon",
        "GitPullRequestIcon",
        "GithubIcon",
        "GitlabIcon",
        "GlobeIcon",
        "GridIcon",
        "HardDriveIcon",
        "HashIcon",
        "HeadphonesIcon",
        "HeartIcon",
        "HelpCircleIcon",
        "HexagonIcon",
        "HomeIcon",
        "ImageIcon",
        "InboxIcon",
        "InfoIcon",
        "InstagramIcon",
        "ItalicIcon",
        "KeyIcon",
        "LayersIcon",
        "LayoutIcon",
        "LifeBuoyIcon",
        "Link2Icon",
        "LinkIcon",
        "LinkedinIcon",
        "ListIcon",
        "LoaderIcon",
        "LockIcon",
        "LogInIcon",
        "LogOutIcon",
        "MailIcon",
        "MapPinIcon",
        "MapIcon",
        "Maximize2Icon",
        "MaximizeIcon",
        "MehIcon",
        "MenuIcon",
        "MessageCircleIcon",
        "MessageSquareIcon",
        "MicOffIcon",
        "MicIcon",
        "Minimize2Icon",
        "MinimizeIcon",
        "MinusCircleIcon",
        "MinusSquareIcon",
        "MinusIcon",
        "MonitorIcon",
        "MoonIcon",
        "MoreHorizontalIcon",
        "MoreVerticalIcon",
        "MousePointerIcon",
        "MoveIcon",
        "MusicIcon",
        "Navigation2Icon",
        "NavigationIcon",
        "OctagonIcon",
        "PackageIcon",
        "PaperclipIcon",
        "PauseCircleIcon",
        "PauseIcon",
        "PenToolIcon",
        "PercentIcon",
        "PhoneCallIcon",
        "PhoneForwardedIcon",
        "PhoneIncomingIcon",
        "PhoneMissedIcon",
        "PhoneOffIcon",
        "PhoneOutgoingIcon",
        "PhoneIcon",
        "PieChartIcon",
        "PlayCircleIcon",
        "PlayIcon",
        "PlusCircleIcon",
        "PlusSquareIcon",
        "PlusIcon",
        "PocketIcon",
        "PowerIcon",
        "PrinterIcon",
        "RadioIcon",
        "RefreshCcwIcon",
        "RefreshCwIcon",
        "RepeatIcon",
        "RewindIcon",
        "RotateCcwIcon",
        "RotateCwIcon",
        "RssIcon",
        "SaveIcon",
        "ScissorsIcon",
        "SearchIcon",
        "SendIcon",
        "ServerIcon",
        "SettingsIcon",
        "Share2Icon",
        "ShareIcon",
        "ShieldOffIcon",
        "ShieldIcon",
        "ShoppingBagIcon",
        "ShoppingCartIcon",
        "ShuffleIcon",
        "SidebarIcon",
        "SkipBackIcon",
        "SkipForwardIcon",
        "SlackIcon",
        "SlashIcon",
        "SlidersIcon",
        "SmartphoneIcon",
        "SmileIcon",
        "SpeakerIcon",
        "SquareIcon",
        "StarIcon",
        "StopCircleIcon",
        "SunIcon",
        "SunriseIcon",
        "SunsetIcon",
        "TabletIcon",
        "TagIcon",
        "TargetIcon",
        "TerminalIcon",
        "ThermometerIcon",
        "ThumbsDownIcon",
        "ThumbsUpIcon",
        "ToggleLeftIcon",
        "ToggleRightIcon",
        "ToolIcon",
        "Trash2Icon",
        "TrashIcon",
        "TrelloIcon",
        "TrendingDownIcon",
        "TrendingUpIcon",
        "TriangleIcon",
        "TruckIcon",
        "TvIcon",
        "TwitchIcon",
        "TwitterIcon",
        "TypeIcon",
        "UmbrellaIcon",
        "UnderlineIcon",
        "UnlockIcon",
        "UploadCloudIcon",
        "UploadIcon",
        "UserCheckIcon",
        "UserMinusIcon",
        "UserPlusIcon",
        "UserXIcon",
        "UserIcon",
        "UsersIcon",
        "VideoOffIcon",
        "VideoIcon",
        "VoicemailIcon",
        "Volume1Icon",
        "Volume2Icon",
        "VolumeXIcon",
        "VolumeIcon",
        "WatchIcon",
        "WifiOffIcon",
        "WifiIcon",
        "WindIcon",
        "XCircleIcon",
        "XOctagonIcon",
        "XSquareIcon",
        "XIcon",
        "YoutubeIcon",
        "ZapOffIcon",
        "ZapIcon",
        "ZoomInIcon",
        "ZoomOutIcon",
      ],
      i18n: null,
      perPage: 10,
      pageOptions: [10, 30, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      fields: [
        {
          key: "no",
          label: "No",
        },
        { key: "urutan", label: "Urutan", sortable: true },
        { key: "name", label: "Nama", sortable: true },
        { key: "icon", label: "Icon"},
        { key: "url", label: "URL"},
        { key: "create", label: "Add"},
        { key: "update", label: "Edit"},
        { key: "delete", label: "Delete"},
        { key: "actions", label: "Aksi" },
      ],
      items: [],
      status: [
        {
          1: "Current",
          2: "Professional",
          3: "Rejected",
          4: "Resigned",
          5: "Applied",
        },
        {
          1: "light-primary",
          2: "light-success",
          3: "light-danger",
          4: "light-warning",
          5: "light-info",
        },
      ],
      listRoutes: []
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  mounted() {
    this.getListRoute()
    this.getData();
  },
  methods: {
    getListRoute() {
      // Set the initial number of items
      let routes = []
      this.$router.options.routes.map((route  , i) => {
        if(route.meta && route.meta.pageTitle) {
          routes.push({
            text: route.meta.pageTitle + ' (URL : ' + route.path + ')',
            value: route.path
          })
        }
      })
      this.listRoutes = routes
    },
    add() {
      this.id = null;
      this.activeAction = "tambah";
      this.showModalAdd = true;
      this.showModalEdit = false;
    },
    remove(item ){
      this.$swal({
        title: 'Anda yakin?',
        text: `Data menu "${item.name}" ini akan dihapus`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ya',
        cancelButtonText: 'Batal',
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          item.fungsi = 1 // soft delete
          this.$store.dispatch('menu/save', [item])
          .then(() => {
            this.getData()
            this.pesanBerhasilHapus()
          })
          .catch(e => {
            this.pesanGagalHapus()
            this.displayError(e)
            return false
          })
        }
      })
    },
    resetForm() {
      this.id = null;
      this.form= {
        id: null,
      create: false,
      update: false,
      delete: false,
      urutan: null,
      name: null,
      url: null,
      icon: "",
      }
    },
    submit() {
      if (this.form.name == null || this.form.name == "") {
        this.pesanGagalSimpan();
        return false;
      }
      if(this.id) {
            this.form.id = this.id
          }
          this.form.i18n = this.form.name
          if(this.query) {
            this.form.url += '' + this.query
          }
          const payload = this.form
      this.$store
        .dispatch("menu/save", [payload])
        .then(() => {
          if (this.activeAction == "tambah") {
            this.id = null;
          }
          this.getData()
          this.pesanBerhasilSimpan()
          this.resetForm()
          this.showModalAdd = false
          this.showModalEdit = false
        })
        .catch((e) => console.error(e));
    },
    edit(item) {
      this.id = item.id;
      this.form = item;
      this.showModalEdit = true;
    },
    pesanBerhasilSimpan() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notifikasi",
            icon: "CheckIcon",
            text: "Berhasil menyimpan data",
            variant: "success",
          },
        },
        {
          position: "bottom-right",
        }
      );
    },
    pesanGagalSimpan() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notifikasi",
            icon: "CheckIcon",
            text: "Gagal menyimpan data, harap cek kembali data yang diisi",
            variant: "warning",
          },
        },
        {
          position: "bottom-right",
        }
      );
    },
    pesanBerhasilHapus() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notifikasi",
            icon: "CheckIcon",
            text: "Berhasil menghapus data",
            variant: "success",
          },
        },
        {
          position: "bottom-right",
        }
      );
    },
    pesanGagalHapus() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notifikasi",
            icon: "CheckIcon",
            text: "Gagal menghapus data, harap cek kembali data yang diisi",
            variant: "warning",
          },
        },
        {
          position: "bottom-right",
        }
      );
    },
    getData() {
      this.$store
        .dispatch("menu/getData", {order: 'desc'})
        .then(() => {
          this.items = this.$store.state.menu.datas;
          this.totalRows = this.items.length;
        })
        .catch((e) => console.error(e));
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>
